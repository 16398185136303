import React from 'react';
import { Link } from 'gatsby';
import { Button, Space } from 'antd';
import { AppstoreAddOutlined, SearchOutlined, UnorderedListOutlined } from '@ant-design/icons';

import './homepage.css';

// 首页 新建购买记录，查询修改购买记录导航按钮

const HomeNav = () => {
  return (
    <div className="home-page-div">
      <Space direction="vertical" size="large">
        <Link className="nav-link" to="/purchaserecords">
          <Button type="primary" size="large" icon={<AppstoreAddOutlined />}>购买记录</Button>
        </Link>
        {/* <Link className="nav-link" to="/queryeditrecords">
          <Button type="primary" size="large" icon={<SearchOutlined />}>查询编辑</Button>
        </Link> */}
        <Link className="nav-link" to="/customers">
          <Button type="primary" size="large" icon={<UnorderedListOutlined />}>顾客列表</Button>
        </Link>
        <Link className="nav-link" to="/lensprice">
          <Button type="primary" size="large" icon={<UnorderedListOutlined />}>镜片价格</Button>
        </Link>
        {/* <Link className="nav-link" to="/lensprice">
          <button className="bg-blue-500 hover:bg-blue-400 text-white p-2 rounded">{<UnorderedListOutlined />} 镜片价格</button>
        </Link> */}
      </Space>
    </div>
  )
}

export default HomeNav;
