import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import HomeNav from '../components/page_home';

const IndexPage = () => (
  <SiteLayout>
    <SEO title="Home" />
    <HomeNav></HomeNav>
  </SiteLayout>
)

export default IndexPage;
